import { Grid, Typography } from '@material-ui/core'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { Markup } from 'interweave'
import { parse } from 'query-string'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useAppState } from '../appState'
import chirpyestProImg from '../assets/images/chirpyest-pro.jpg'
import profileActiveIcon from '../assets/images/profile-active.svg'
import axios from '../axios'
import MiddleSection from '../components/homeSections/homeMiddleSection'
import ItemsPerPageFilter from '../components/ItemsPerPageFilter'
import ProductsSort from '../components/productsSort'
import Spinner from '../components/spinner'
import Card from '../components/trendingCard'
import { ENDPOINTS, ROUTES } from '../constants'
import Layout from '../layouts/defaultLayout'
import { styles } from '../pagesStyles/editPagesStyles'
import CHRArrowButton from './../components/arrowButton'
import CHRSectionContainer from './../components/sectionContainer'
import NotFound from './404'

const editPages = ({ t, editPageId }) => {
  const classes = styles()

  const { search } = useLocation()
  const { page, size, sort } = parse(search)
  const [appState] = useAppState()

  const [editPage, setEditPage] = useState(null)
  const [sorting, setSorting] = useState(sort || 'trending')
  const [pageSize, setPageSize] = useState(Number(size) || 100)
  const [loadingProducts, setLoadingProducts] = useState(true)
  const [products, setProducts] = useState([])
  const [randomPosition, setRandomPosition] = useState(null)
  const [pageNumber, setPageNumber] = useState(Number(page) || 1)
  const [error, setError] = useState('')

  const sortingOptions: any = {
    trending: {
      value: 'sort=trending',
      type: 'trending',
      label: t('memberHome.trending'),
    },
    highestToLowest: {
      value: 'sort=price&dir=desc',
      type: 'highestToLowest',
      label: t('shared.highestToLowest'),
    },
    lowestToHighest: {
      value: 'sort=price&dir=asc',
      type: 'lowestToHighest',
      label: t('shared.lowestToHighest'),
    },
  }

  const updateProducts = (productId: string, shoppingBoardId: number) => {
    setProducts(items => {
      const i = items.findIndex(e => e.product_id === productId)
      return [
        ...items.slice(0, i),
        {
          ...items[i],
          shoppingBoardId,
        },
        ...items.slice(i + 1),
      ]
    })
  }

  useEffect(() => {
    ;(async () => {
      try {
        setPageNumber(Number(page) || 1)
        setSorting(sort || 'trending')
        setPageSize(Number(size) || 100)
        setLoadingProducts(true)

        const endpoint = `${ENDPOINTS.editPage}/${editPageId}?size=${
          pageNumber === 1 ? pageSize - 2 : pageSize
        }&offset=${pageNumber === 1 ? 0 : pageSize * (pageNumber - 1) - 2}&${
          sortingOptions[sorting].value
        }`

        const res = await axios.get(endpoint)

        setProducts(res.data.data.products)
        setEditPage(res.data.data.editPage)
        let randomNumber = Math.floor(Math.random() * 15 + 8)
        const edgePositions = [11, 15, 19]
        edgePositions.includes(randomNumber) &&
          (randomNumber = randomNumber - 1)
        setRandomPosition(randomNumber)
        setLoadingProducts(false)
      } catch (error) {
        setLoadingProducts(false)
        setError(t('messages.somethingWentWrong'))
      }
    })()
  }, [editPageId])

  const handleSorting = async ({ type, value }) => {
    try {
      setLoadingProducts(true)
      setSorting(type)
      const endpoint = `${ENDPOINTS.editPage}/${editPageId}?size=${pageSize -
        2}&offset=0&${value}`

      window.history.pushState('', '', `?sort=${type}&page=1&size=${pageSize}`)

      const res = await axios.get(endpoint)
      setProducts(res.data.data.products)
      setPageNumber(1)
      setLoadingProducts(false)
    } catch (error) {
      setLoadingProducts(false)
      setError(t('messages.somethingWentWrong'))
    }
  }

  const handlePageSizeChange = async (newSize: number) => {
    try {
      const totalSkippedItems = pageNumber * pageSize
      const newPageNumber = Math.ceil(totalSkippedItems / newSize)

      setLoadingProducts(true)
      setPageNumber(newPageNumber)
      setPageSize(newSize)

      const endpoint = `${
        ENDPOINTS.editPage
      }/${editPageId}?size=${newSize}&offset=${newSize * (newPageNumber - 1)}&${
        sortingOptions[sorting].value
      }`

      window.history.pushState(
        '',
        '',
        `?sort=${sorting}&page=${newPageNumber}&size=${newSize}`
      )

      const res = await axios.get(endpoint)

      setProducts(res.data.data.products)
      setLoadingProducts(false)
    } catch (error) {
      setLoadingProducts(false)
      setError(t('messages.somethingWentWrong'))
    }
  }

  const navigatePage = async (
    pageNumber: number,
    size: number,
    offset: number
  ) => {
    try {
      window.scroll({
        top: 200,
        behavior: 'auto',
      })
      setLoadingProducts(true)
      const endpoint = `${ENDPOINTS.editPage}/${editPageId}?size=${size}&offset=${offset}&${sortingOptions[sorting].value}`

      window.history.pushState(
        '',
        '',
        `?sort=${sorting}&page=${pageNumber - 1}&size=${pageSize}`
      )

      const res = await axios.get(endpoint)

      setProducts(res.data.data.products)
      setPageNumber(pageNumber)
      setLoadingProducts(false)
    } catch (error) {
      setLoadingProducts(false)
      setError(t('messages.somethingWentWrong'))
    }
  }

  const fetchPreviousPage = async () => {
    const page = pageNumber - 1
    const size = pageNumber === 2 ? pageSize - 2 : pageSize
    const offset = pageNumber === 2 ? 0 : pageSize * (pageNumber - 2) - 2
    await navigatePage(page, size, offset)
  }

  const fetchNextPage = async () => {
    const page = pageNumber + 1
    const size = pageSize
    const offset = pageSize * pageNumber - 2
    await navigatePage(page, size, offset)
  }

  if (error) {
    return <NotFound error={error} />
  }

  if (!editPage) {
    if (loadingProducts) return <Spinner />
    return <NotFound />
  }

  const productsCount = products.length

  return (
    <Layout>
      {/* Heading */}
      <div className={classes.headingWrapper}>
        <div className={classes.headingContainer}>
          <div className={classes.headingTextContainer}>
            <div className={classes.headingLogoContainer}>
              <img
                src={`${editPage.logo ? editPage.logo : profileActiveIcon}`}
                className={classes.headingLogoImage}
                alt="logo"
              />
              <div className={classes.headingLogoText}>
                <Typography
                  component="p"
                  className={classes.chirpyestPageTitle}
                  variant="subtitle2"
                >
                  chirpyest edit
                </Typography>
                <Typography
                  component="h1"
                  className={classes.chirpyestPageHeader}
                  variant="h1"
                >
                  {editPage.title}
                </Typography>
                <Typography
                  component="p"
                  className={classes.description}
                  variant="subtitle2"
                >
                  <Markup content={editPage.description} />
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.actionButtonsContainer}>
            <div className={classes.filtersContainer}>
              <div className={classes.filterButton}>
                <ProductsSort
                  label={t('shared.sortBy')}
                  onFilterChange={handleSorting}
                  selectedFilter={sortingOptions[sorting]}
                  options={Object.values(sortingOptions)}
                />
              </div>
              {/* <div className={classes.filterButton}>
                <ProductsSort
                  label={t('shared.refine')}
                  onFilterChange={() => console.log('filter')}
                  selectedFilter=""
                  options={[]}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={classes.productsContainer}>
          <div className={classes.countingFilterContainer}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item lg={3} md={4} sm={4} xs={6}>
                <Typography
                  component="p"
                  className={classes.itemsCount}
                  variant="body1"
                >
                  {productsCount}
                  {productsCount >= 10000 ? '+' : ''} {t('shared.items')}
                </Typography>
              </Grid>
              <Grid
                item
                lg={3}
                md={4}
                sm={4}
                xs={6}
                style={{
                  backgroundColor: '#FAFBFD',
                  padding: 0,
                }}
              >
                <ItemsPerPageFilter
                  onFilterChange={handlePageSizeChange}
                  selectedFilter={pageSize}
                />
              </Grid>
            </Grid>
          </div>
          <Grid container alignItems="stretch" spacing={2}>
            {loadingProducts ? (
              <Spinner />
            ) : (
              products.map((product, index) => {
                if (index === randomPosition && pageNumber === 1) {
                  return (
                    <>
                      <Grid item lg={6} md={8} sm={12} xs={12}>
                        <div className={classes.joinCard}>
                          <Typography
                            variant="h4"
                            className={classes.joinCardText}
                          >
                            {editPage.cardText
                              ? editPage.cardText
                              : `we love these products. our ${new Date().getFullYear()}
                            favorites!`}
                          </Typography>
                        </div>
                      </Grid>
                    </>
                  )
                }
                return (
                  <Grid
                    item
                    lg={3}
                    md={4}
                    sm={6}
                    xs={6}
                    key={product.product_id}
                  >
                    <Card
                      updateProducts={updateProducts}
                      userId={appState.userId}
                      productInfo={product.data}
                      shareable
                    />
                  </Grid>
                )
              })
            )}
          </Grid>
          <div className={classes.paginationContainer}>
            <CHRArrowButton
              isLeftArrow
              label={t('shared.previous')}
              onClick={fetchPreviousPage}
              disabled={pageNumber === 1 || !productsCount}
            />
            <Typography variant="subtitle2" component="p">
              {pageNumber}/{Math.ceil(productsCount / pageSize) || 1}
            </Typography>
            <CHRArrowButton
              label={t('shared.next')}
              onClick={fetchNextPage}
              disabled={
                pageNumber === Math.ceil(productsCount / pageSize) ||
                !productsCount
              }
            />
          </div>
        </div>
      </div>
      {/* Pro section */}
      <CHRSectionContainer>
        <section>
          <MiddleSection
            handleArrowClick={() => navigate(ROUTES.chirpyestPro)}
            image={chirpyestProImg}
            buttonLabel={t('memberHome.learnMore')}
            rightChildren={
              <div>
                <Typography variant="h3">{t('memberHome.proTitle')}</Typography>
                <Typography variant="h2">
                  {t('memberHome.proSubtitle')}
                </Typography>
                <Typography variant="subtitle1" component="p">
                  {t('memberHome.proText')}
                </Typography>
              </div>
            }
          />
        </section>
      </CHRSectionContainer>
    </Layout>
  )
}
export default withTranslation()(editPages)
